<template>
    <v-container fluid>
        <v-card :loading="loading" class="mt-3">
            <Page-Header
                :title="$t('systemLog.systemLog')"
                icon="mdi-book-open-variant"
            >
            </Page-Header>
            <v-data-table
                :headers="headers"
                :items="systemLog"
                :search="search"
                :loading="loading"
                class="pa-5"
                style="overflow: hidden;"
                :loading-text="$t('loading')"
            >
                <template v-slot:top>
                    <!--Search bar-->
                    <v-text-field
                        dense
                        outlined
                        :loading="loading"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('search')"
                        single-line
                        hide-details
                    ></v-text-field>
                </template>
                <template v-slot:[`item.auditAction`]="{ item }">
                    {{ actionsList[item.auditAction] }}
                </template>
                <template v-slot:[`item.tableName`]="{ item }">
                    {{ $t("dbTables." + item.tableName) }}
                </template>
                <template v-slot:[`item.auditDateTime`]="{ item }">
                    {{ item.auditDateTime | moment("HH:mm a") }}
                </template>
                <template v-slot:[`item.date`]="{ item }">
                    {{ item.auditDateTime | moment("yyyy-MM-DD") }}
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: { PageHeader },
    data() {
        return {
            loading: true,
            search: "",
            headers: [
                {
                    text: this.$t("systemLog.tableName"),
                    value: "tableName"
                },
                {
                    text: this.$t("systemLog.action"),
                    value: "auditAction"
                },
                {
                    text: this.$t("systemLog.date"),
                    value: "date"
                },
                {
                    text: this.$t("systemLog.time"),
                    value: "auditDateTime"
                },
                {
                    text: this.$t("systemLog.user"),
                    value: "userName"
                }
            ],
            systemLog: [],
            actionsList: {
                Update: this.$t("update"),
                Delete: this.$t("delete"),
                Insert: this.$t("insert")
            }
        };
    },
    created() {
        if (this.isInRole(0)) {
            this.loading = true;
            this.refreshTable();
        } else this.redirectUnauthorizedUsers();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.systemLog.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        refreshTable() {
            try {
                axios
                    .get("AuditLogs/Get")
                    .then(response => {
                        this.systemLog = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
