var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mt-3",attrs:{"loading":_vm.loading}},[_c('Page-Header',{attrs:{"title":_vm.$t('systemLog.systemLog'),"icon":"mdi-book-open-variant"}}),_c('v-data-table',{staticClass:"pa-5",staticStyle:{"overflow":"hidden"},attrs:{"headers":_vm.headers,"items":_vm.systemLog,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","loading":_vm.loading,"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.auditAction",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.actionsList[item.auditAction])+" ")]}},{key:"item.tableName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("dbTables." + item.tableName))+" ")]}},{key:"item.auditDateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.auditDateTime,"HH:mm a"))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.auditDateTime,"yyyy-MM-DD"))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }